<template>
	<div>
		<template-base class="containerCard">
			<div>
				<div class="container-head">
					<div
						v-if="possuiPermissao('REL_V_CONTAS')"
						class="filter"
					>
						<b-col cols="1" class="container-btn-print">
							<b-dropdown no-caret left variant="link" title="Imprimir">
								<template #button-content>
									<printer-icon class="icon-print" />
								</template>
								<b-dropdown-item @click="exportData('csv')">CSV</b-dropdown-item>
								<b-dropdown-item @click="exportData('xlsx')">EXCEL</b-dropdown-item>
								<b-dropdown-item @click="exportData('pdf')">PDF</b-dropdown-item>
							</b-dropdown>
						</b-col>
					</div>
				</div>
				<div v-if="linhas.length" class="head-row mb-2 width-pagination-items mt-1">
					<div class="text-head-row width-pagination">
						<label class="text-head-row ml-0">
							Resultado {{ pagination.offset + 1 }}-{{ pagination.offset + (linhas.length - 1) }} de {{ pagination.count }}
						</label>
						<b-pagination
							class="mr-0 mb-2 ml-3"
							id="page"
							v-model="currentPage"
							:total-rows="pagination.count"
							:per-page="pagination.limit.value"
						></b-pagination>
					</div>
					<b-form
						class="text-head-row width-pagination">
						<label class="text-head-row ml-0 w-60">
							Itens por página
						</label>
						<b-form-select
							class="mr-0 mb-2"
							v-model="pagination.limit.value"
							name="itens-por-pagina"
							:options="optionsItensPorPag"
							@input="changeItensByPage()"
							:disabled="loading"
						/>
					</b-form>
				</div>
			</div>
			<div v-if="linhas.length" class="table-responsive">
				<DataTable v-if="!isMobile"
					class="data-table"
					:loading="loading"
					:colunas="colunas"
					:linhas="linhas"
					:errMsg="errMsg"
					:nosearch="true"
					:nofilters="true"
					name="contas"
					:hasPagination="true"
					:noedit="true"
					:acoes="false"
					:details="true"
					:alignColumns="alignColumnsTable"
					@clickSortTableBy="(v) => sortTableBy(v)"
					:state="{
						sortBy: sortTable.sortBy,
						sortAsc: sortTable.order === 1,
						query: ''
					}"
					:async="true"
					@clickDetails="showDetailsAccount"
				/>
				<CardTransaction v-else
					:loading="loading"
					:colunas="colunas"
					:linhas="linhas"
					:errMsg="errMsg"
					:acoes="false"
					:globalAcoes="false"
					:colsDataCard="colsDataCard"
					:showSelectItem="false"
					:details="true"
					@clickDetails="showDetailsAccount"
				/>
			</div>
			<div v-else class="alert alert-warning" role="alert">
				{{ errMsg }}
			</div>
			<vue-html2pdf
				:show-layout="false"
				:enable-download="true"
				:preview-modal="false"
				:paginate-elements-by-height="1400"
				:pdf-quality="2"
				pdf-format="a4"
				pdf-orientation="landscape"
				pdf-content-width="600px"
				:html-to-pdf-options="htmlToPdfOptions"
				ref="html2Pdf"
				:float-layout="floatLayout"
				:manual-pagination="false"
				@hasDownloaded="finishGeneratePdf"
			>
				<section slot="pdf-content">
					<ReportPDF
						:title="title"
						:rows="rowsReport"
						:headerData="headerData"
						:fields="fields"
					/>
				</section>
			</vue-html2pdf>
			<b-modal
				ref="modalDetailsAccount"
				hide-footer
				:hide-header="hideHeaderModal"
				title="Detalhes da conta"
				size="lg"
				no-overflow
				hide-no-focus
			>
				<div>
					<div v-if="hideHeaderModal" class="title-modal" style="top: 1Rem">
						<div class="title-text-modal">Detalhes da conta {{ desciptionAccountDetails || ""}}</div>
						<div
							class="float-right btn-hover-modal"
							@click="closeModal"
						>
							<xIcon/>
						</div>
					</div>
					<div class="mt-5 mb-3">
						<div class="filter-wrap">
							<div
								class="filter"
								v-bind:class="[showFiltersForm ? 'filter-active' : '']"
								@click="filterDropDownActive"
								title="Alterar Filtros"
							>
								<FilterIcon/>
							</div>
							<div
								v-if="possuiPermissao('REL_V_CONTAS')"
								class="filter ml-2"
							>
								<b-col class="container-btn-print" cols="1">
									<b-dropdown no-caret left variant="link" title="Imprimir">
										<template #button-content>
											<printer-icon class="icon-print" />
										</template>
										<b-dropdown-item @click="exportDataAccount('csv')">CSV</b-dropdown-item>
										<b-dropdown-item @click="exportDataAccount('xlsx')">EXCEL</b-dropdown-item>
										<b-dropdown-item @click="exportDataAccount('pdf')">PDF</b-dropdown-item>
									</b-dropdown>
								</b-col>
							</div>
						</div>
						<div v-if="showFiltersForm" class="filters-form content-card responsive-filter-modal" style="display: block !important">
							<b-row class="filters-orientation filters-section">
								<b-col class="filter-item">
									<label>Data inicial</label>
									<div class="d-flex">
										<datepicker
											class="w-100"
											placeholder="Início"
											id="data-inicial-historica"
											input-class="bg-white"
											v-model="inicio"
											:format="formatter"
											:language="ptBR"
											:bootstrap-styling="true"
											data-cy="Data Inicial"
											@input="setFilterDateEnd(inicio)"
										/>
									</div>
								</b-col>
								<b-col class="filter-item">
									<label>Data final</label>
									<div class="d-flex">
										<datepicker
											class="w-100"
											placeholder="Fim"
											id="data-final-historica"
											input-class="bg-white"
											v-model="fim"
											:format="formatter"
											:language="ptBR"
											:bootstrap-styling="true"
											data-cy="Data Final"
										/>
									</div>
								</b-col>
							</b-row>
							<div class="mt-4 search-button" @click="showDetailsAccount">
								<SearchIcon/>
							</div>
						</div>
					</div>
					<div v-if="linhas.length" class="table-responsive">
						<DataTable
							class="data-table"
							:loading="isLoading"
							:colunas="detailsColunas"
							:linhas="detailsAccount"
							errMsg="Erro ao obter informações"
							:nosearch="true"
							:nofilters="true"
							name="detailsAccount"
							:hasPagination="true"
							:noedit="true"
							:acoes="false"
							:selectItem="false"
							:alignColumns="detailsAlignColumnsTable"
							scroll
							:sortable="true"
							:state="{
								sortBy: sortTable.sortBy,
								sortAsc: 0,
								query: ''
							}"
							:async="true"
						/>
					</div>
					<div v-else class="alert alert-warning" role="alert">
						Erro ao obter informações
					</div>
				</div>
			</b-modal>
		</template-base>
	</div>
</template>

<script>
	import DataTable from "@/components/DataTable";
	import TemplateBase from "@/templates/Base";

	import { possuiPermissao } from "../../helpers/permissions";
	import { AccountService } from "../../services/account";
	import { getDateMonthEnd, numberFormatted, exportCSVFile, formatterSimple } from "@/helpers/common";
	import dayjs from "dayjs";
	import "dayjs/locale/pt-br";
	import Datepicker from "vuejs-datepicker";
	import { ptBR } from "vuejs-datepicker/dist/locale";

	import * as XLSX from "xlsx";
	import VueHtml2pdf from "vue-html2pdf";
	import ReportPDF from "./ReportPDF";
	import { TransactionService } from "../../services/transaction";
	import CardTransaction from "@/components/CardTransaction.vue";

	export default {
		components: {
			TemplateBase,
			DataTable,
			VueHtml2pdf,
			ReportPDF,
			Datepicker,
			CardTransaction
		},

		data () {
			return {
				title: "",
				userName: JSON.parse(localStorage.params).nomeUsuario,
				linhas: [],
				loading: true,
				accountService: new AccountService(),
				errMsg: "Nenhuma conta com balance cadastrada!",
				colunas: [
					"Id",
					"Conta",
					"Rendas",
					"Despesas",
					"Saldo",
					"Tipo de Conta"
				],
				colsDataCard: {
					0: {
						idx: 1,
						label: "Conta",
						textAlign: "left",
						cols: 6,
						visible: true
					},
					1: {
						idx: 5,
						label: "Tipo de Conta",
						textAlign: "left",
						cols: 6,
						visible: true
					},
					2: {
						idx: 2,
						label: "Rendas",
						textAlign: "right",
						cols: 4,
						visible: true
					},
					3: {
						idx: 3,
						label: "Despesas",
						textAlign: "right",
						cols: 4,
						visible: true
					},
					4: {
						idx: 4,
						label: "Saldo",
						textAlign: "right",
						cols: 4,
						visible: true
					},
					5: {
						idx: null,
						label: "",
						textAlign: "",
						cols: 0,
						visible: false
					},
					6: {
						idx: null,
						label: "",
						textAlign: "left",
						cols: 12,
						visible: false
					},
					7: {
						idx: null,
						label: "",
						textAlign: "",
						cols: 0,
						visible: false
					}

				},
				alignColumnsTable: [
					"left",
					"left",
					"right",
					"right",
					"right",
					"left"
				],
				pagination: {
					count: 0,
					page: 1,
					offset: 0,
					limit: {
						value: 25
					},
					pages: 1
				},
				currentPage: 1,
				optionsItensPorPag: [
					{
						value: 5,
						text: "5 Items"
					},
					{
						value: 25,
						text: "25 Items"
					},
					{
						value: 50,
						text: "50 Items"
					},
					{
						value: 75,
						text: "75 Items"
					},
					{
						value: 100,
						text: "100 Items"
					}
				],
				possuiPermissao,
				fields : [],
				headerData: {},
				rowsReport: [],
				sortTable: {
					sortBy: 1,
					order: -1
				},
				hideHeaderModal: true,
				detailsAccount: [],
				transactionService: new TransactionService(),
				detailsColunas: [
					"#",
					"Data",
					"Rendas",
					"Despesas",
					"Saldo",
					"Observações"
				],
				detailsAlignColumnsTable: [
					"left",
					"left",
					"right",
					"right",
					"right",
					"left"
				],
				isLoading: true,
				balanceExpense: 0,
				balanceIncome: 0,
				balanceAccount: 0,
				showFiltersForm: false,
				inicio: null,
				fim: null,
				idAccountDetails: [],
				desciptionAccountDetails: "",
				ptBR,
				floatLayout: true,
				isMobile: false
			};
		},

		computed: {
			htmlToPdfOptions () {
				return {
					margin: [10, 0, 10, 0],
					image: {
						type: "jpeg",
						quality: 0.98
					},
					enableLinks: false,
					filename: `${this.title}.pdf`,
					html2canvas: {
						scale: 1,
						useCORS: true
					},

					jsPDF: {
						format: "a4",
						orientation: "landscape",
						floatPrecision: 16
					}
				};
			}

		},

		watch: {
			currentPage () {
				this.changePage(this.currentPage);
			}
		},

		async mounted () {
			this.checkIfMobile();
			window.addEventListener("resize", this.checkIfMobile);

			await this.findContas();
			this.inicio = new Date();
			this.fim = new Date();
			this.inicio.setDate("1");
		},

		beforeDestroy () {
			window.removeEventListener("resize", this.checkIfMobile);
		},

		methods: {
			checkIfMobile	() {
				this.isMobile = window.innerWidth <= 768;
			},

			sortTableBy (v) {
				this.sortTable = v;
				this.findContas();
			},

			changePage () {
				this.pagination.page = this.currentPage;
				this.findContas();
			},

			changeItensByPage () {
				this.pagination.page = 1;
				this.findContas();
			},

			async findContas () {
				const limit = this.pagination.limit.value;
				this.pagination.offset = (this.pagination.page - 1) * limit;
				const offset = this.pagination.offset;
				const automaticPay = true;
				const sortTable = this.sortTable;

				const filters = {
					offset,
					limit,
					automaticPay,
					sortTable,
					reportBalance: true
				};

				const result = await this.accountService.listAccount(filters) || {};
				this.linhas = [];
				let incomeAccounts = 0;
				let expensesAccounts = 0;
				let balanceAccounts = 0;

				result.rows.forEach((c) => {
					incomeAccounts = incomeAccounts + parseFloat(c.income);
					expensesAccounts = expensesAccounts + parseFloat(c.expenses);
					balanceAccounts = balanceAccounts + parseFloat(c.balance);

					this.linhas.push({
						idAccount: c.idAccount,
						cols: [
							c.idAccount.toString(),
							c.description,
							numberFormatted(c.income || "0,00"),
							numberFormatted(c.expenses || "0,00"),
							numberFormatted(c.balance || "0,00"),
							c.accountClass.description || ""
						]
					});
				});

				if (result.rows.length) {
					this.linhas.push({
						noDetails: true,
						hideLine1: true,
						hideLine2: false,
						hideLine3: true,
						cols: [
							"",
							"Total Contas",
							numberFormatted(incomeAccounts),
							numberFormatted(expensesAccounts),
							numberFormatted(balanceAccounts),
							""
						]
					});
				}

				this.pagination.count = result.count || 0;
				this.pagination.pages = this.pagination.count > limit ? Math.ceil(this.pagination.count / limit) : 1;
				this.loading = false;
			},

			async exportData (type) {
				try {
					const filters = {
						automaticPay: true,
						sortTable: this.sortTable,
						reportBalance: true
					};
					this.loading = true;
					this.title = "Relatório de Balance de Contas";
					const data = await this.accountService.listAccount(filters) || {};

					const exportType = {
						pdf: ["id", "description", "income", "expenses", "amount", "accountClassDescription"],
						xlsx: ["Id", "Conta", "Rendas", "Despesas", "Saldo", "Tipo de Conta"],
						csv: ["Id", "Conta", "Rendas", "Despesas", "Saldo", "Tipo de Conta"]
					};

					this.rowsReport = [];
					let incomeAccounts = 0;
					let expensesAccounts = 0;
					let balanceAccounts = 0;

					data.rows.forEach((rec) => {
						incomeAccounts = incomeAccounts + parseFloat(rec.income);
						expensesAccounts = expensesAccounts + parseFloat(rec.expenses);
						balanceAccounts = balanceAccounts + parseFloat(rec.balance);
						this.rowsReport.push({
							[exportType[type][0]]: rec.idAccount,
							[exportType[type][1]]: rec.description,
							[exportType[type][2]]: numberFormatted(rec.income || "0,00"),
							[exportType[type][3]]: numberFormatted(rec.expenses || "0,00"),
							[exportType[type][4]]: numberFormatted(rec.balance || "0,00"),
							[exportType[type][5]]: rec.accountClass.description
						});
					});
					this.rowsReport.push({
						[exportType[type][0]]: "",
						[exportType[type][1]]: "Total Contas",
						[exportType[type][2]]: numberFormatted(incomeAccounts || "0,00"),
						[exportType[type][3]]: numberFormatted(expensesAccounts || "0,00"),
						[exportType[type][4]]: numberFormatted(balanceAccounts || "0,00"),
						[exportType[type][5]]: ""
					});

					if (type === "csv") {
						exportCSVFile(this.rowsReport, this.title);
						this.loading = false;
					} else if (type === "xlsx") {
						const worksheet = XLSX.utils.json_to_sheet(this.rowsReport);
						const workbook = XLSX.utils.book_new();
						XLSX.utils.book_append_sheet(workbook, worksheet, "pagina 1");
						XLSX.writeFile(workbook, `${this.title}.xlsx`);
						this.loading = false;
					} else {
						// type === "pdf"
						this.floatLayout = false;
						this.fields = [
							{ key: "id", label: "Id" },
							{ key: "description", label: "Conta" },
							{ key: "income", label: "Rendas", tdClass: "text-right", thClass: "text-right" },
							{ key: "expenses", label: "Despesas", tdClass: "text-right", thClass: "text-right" },
							{ key: "amount", label: "Saldo", tdClass: "text-right", thClass: "text-right" },
							{ key: "accountClassDescription", label: "Tipo de Conta" }
						];
						this.headerData = {
							date: dayjs().locale("pt-br").format("DD-MM-YYYY"),
							hour: dayjs().locale("pt-br").format("HH:mm:ss"),
							user: this.userName,
							qtdRows: data.length
						};
						this.$refs.html2Pdf.generatePdf();
						this.floatLayout = true;
					}
				} catch (e) {
					console.log(e);
					this.$swal({
						title: "Erro",
						text: `Falha ao exportar dados! - "${e.message}"`,
						type: "error",
						showCancelButton: false,
						confirmButtonColor: "rgb(132, 170, 194)",
						confirmButtonText: "Ok"
					});
				} finally {
					this.loading = false;
				}
			},

			async exportDataAccount (type) {
				try {
					const data = this.detailsAccount;

					const exportType = {
						pdf: ["date", "income", "expenses", "amount", "comments"],
						xlsx: ["Data", "Rendas", "Despesas", "Saldo", "Observações"],
						csv: ["Data", "Rendas", "Despesas", "Saldo", "Observações"]
					};

					this.rowsReport = [];
					data.forEach((rec) => {
						this.rowsReport.push({
							[exportType[type][0]]: rec.cols[1],
							[exportType[type][1]]: rec.cols[2],
							[exportType[type][2]]: rec.cols[3],
							[exportType[type][3]]: rec.cols[4],
							[exportType[type][4]]: rec.cols[5]

						});
					});

					if (type === "csv") {
						exportCSVFile(this.rowsReport, this.desciptionAccountDetails);
					} else if (type === "xlsx") {
						const worksheet = XLSX.utils.json_to_sheet(this.rowsReport);
						const workbook = XLSX.utils.book_new();
						XLSX.utils.book_append_sheet(workbook, worksheet, "pagina 1");
						XLSX.writeFile(workbook, `${this.desciptionAccountDetails}.xlsx`);
					} else {
						// type === "pdf"
						this.floatLayout = false;
						this.fields = [
							{ key: "date", label: "Data" },
							{ key: "income", label: "Rendas", tdClass: "text-right", thClass: "text-right" },
							{ key: "expenses", label: "Despesas", tdClass: "text-right", thClass: "text-right" },
							{ key: "amount", label: "Saldo", tdClass: "text-right", thClass: "text-right" },
							{ key: "comments", label: "Observações" }
						];

						this.headerData = {
							date: dayjs().locale("pt-br").format("DD-MM-YYYY"),
							hour: dayjs().locale("pt-br").format("HH:mm:ss"),
							user: this.userName
						};
						this.$refs.html2Pdf.generatePdf();
						this.floatLayout = true;
					}
				} catch (e) {
					console.log(e);
					this.$swal({
						title: "Erro",
						text: `Falha ao exportar dados! - "${e.message}"`,
						type: "error",
						showCancelButton: false,
						confirmButtonColor: "rgb(132, 170, 194)",
						confirmButtonText: "Ok"
					});
				} finally {

				}
			},

			finishGeneratePdf () {
				this.loading = false;
			},

			async showDetailsAccount (item) {
				if (item && item.idAccount) {
					this.idAccountDetails = [{value: item.idAccount}] || [];
					this.desciptionAccountDetails = item.cols[1];
				}
				this.title = `Relatório de movimentos de conta - ${this.desciptionAccountDetails}`;

				const idAccount = this.idAccountDetails[0].value;
				const filters = {
					idAccount: this.idAccountDetails,
					initDate: this.inicio ? dayjs(this.inicio).locale("pt-br").format("YYYY-MM-DD") : "",
					endDate: this.fim ? dayjs(this.fim).locale("pt-br").format("YYYY-MM-DD") : "",
					previousBalances: true,
					sortTable: {
						order: 0,
						sortBy: 2
					}
				};
				this.isLoading = true;
				const result = await this.transactionService.getTransactionsByParams(filters) || {};
				let record = 0;

				this.balanceExpense = result.balance?.expense || 0;
				this.balanceIncome = result.balance?.income || 0;
				this.balanceAccount = result.balance?.balance || 0;

				let balanceExpense = result.balance?.expense || 0;
				let balanceIncome = result.balance?.income || 0;
				let balanceAccount = result.balance?.balance || 0;
				this.detailsAccount = [];

				if (result.income || result.expenses || result.balance) {
					this.detailsAccount.push({
						selected: false,
						cols: [
							"-",
							`Total até ${formatterSimple(this.inicio)}`,
							numberFormatted(this.balanceIncome),
							numberFormatted(this.balanceExpense),
							numberFormatted(this.balanceAccount),
							""
						]
					});
				}

				result.rows.forEach((c) => {
					record = record + 1;
					if (c.idIncomeAccount && c.idIncomeAccount === idAccount) {
						balanceIncome = balanceIncome + parseFloat(c.amount);
						balanceAccount = balanceAccount + parseFloat(c.amount);
					} else if (c.idExpenseAccount && c.idExpenseAccount === idAccount) {
						balanceExpense = balanceExpense + parseFloat(c.amount);
						balanceAccount = balanceAccount - parseFloat(c.amount);
					}

					this.detailsAccount.push({
						selected: false,
						cols: [
							record.toString(),
							formatterSimple(c.date),
							c.idIncomeAccount && c.idIncomeAccount === idAccount ? numberFormatted(c.amount) : "",
							c.idExpenseAccount && c.idExpenseAccount === idAccount ? numberFormatted(c.amount) : "",
							numberFormatted(balanceAccount),
							c.comments
						]
					});
				}, []);

				if (this.detailsAccount.length && this.detailsAccount.length > 1) {
					const today = new Date();
					let fim = null;
					today.setHours(0, 0, 0, 0);
					if (this.fim) {
						fim = new Date(this.fim);
						fim.setHours(0, 0, 0, 0);
					}

					// if (this.inicio && this.fim && fim < today) {
					if (this.inicio) {
						this.detailsAccount.push({
							cols: [
								"-",
								"Total período",
								numberFormatted(balanceIncome - this.balanceIncome),
								numberFormatted(balanceExpense - this.balanceExpense),
								numberFormatted(balanceAccount - this.balanceAccount),
								""
							]
						});
					}

					this.detailsAccount.push({
						cols: [
							"-",
							"Total conta",
							numberFormatted(balanceIncome),
							numberFormatted(balanceExpense),
							numberFormatted(balanceAccount),
							""
						]
					});
				}
				this.$refs.modalDetailsAccount.show();
				this.isLoading = false;
				this.showFiltersForm = false;
			},

			closeModal () {
				this.$refs.modalDetailsAccount.hide();
			},

			filterDropDownActive () {
				this.showFiltersForm = !this.showFiltersForm;
			},

			setFilterDateEnd (date) {
				this.fim = getDateMonthEnd(date);
			},

			formatter (date) {
				return dayjs(date).locale("pt-br").format("D [de] MMMM [de] YYYY");
			}
		}
	};
</script>

<style scoped>
	.text-conta {
		font-family: 'Roboto Condensed Regular';
		font-size: 20px;
	}

	.label-conta {
		color: #696969 !important;
	}

	.toggle-conta {
		display: flex;
		margin-top: 7px;
		justify-content: center;
	}

	label {
		width: 160px;
	}

	.container-head {
		display: flex;
		height: 3rem;
		width: 100%;
	}

	.container-pages {
		display: flex;
		width: 70%;
		margin-top: 1.5rem;
	}

	.container-btn-print {
		margin-left: -50px;
	}

	.add-button {
		width: 135;
		height: 43;
		font-family: 'Roboto Condensed Medium';
		background-color: #218838;
		color: #FFFFFF;
		font-size: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 5px;
		padding: .75rem 1.5rem;
		border-style: none;
		margin-bottom: .5rem;
	}

	.containerCard {
		max-width: 90.5%;
	}

	.head-row {
		display: flex;
		align-items: center;
		width: 100%;
		justify-content: space-between;
		margin-top: -16px;
	}

	.text-head-row {
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: 'Roboto Condensed Bold';
		letter-spacing: -0.05em;
		font-size: 14px;
		color: #6C757D;
	}

	.modal-edicao-label {
		font-family: 'Roboto Condensed Regular';
		font-size: 23px;
	}

	.modal-edicao-input {
		font-family: 'Roboto Condensed Regular';
		font-size: 20px;
	}

	.table-body-detalhe {
		padding: 5px 0;
	}

	.responsive-filter-modal {
		display: flex;
		justify-content: space-evenly;
	}

	.filter-rows {
		width: 50%;
		padding: 1.25rem 0 1.25rem 1.25rem;
		font-size: 1.15rem;
	}
	.filter-rows-next {
		padding-left: 0.8rem;
		padding-right: 1.25rem;
	}

	@media (max-width: 768px) {
		.responsive-filter-modal {
			display: block;
		}
		.filter-rows {
			padding-left: 1.8rem;
			padding-right: 0;
			width: 100%;
		}
		.filter-rows-next {
			padding-top: 0;
			margin-top: -1rem;
		}

	}
</style>
